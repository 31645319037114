import {
  IToken,
  NetworkType,
  SERVICE_API_URL,
  tokenMap,
  validTiers,
} from "@/config";
import {
  InstanceDeploymentType,
  ServiceDetailsCardStatus,
  ThemeMode,
} from "@spheron/ui-library";
import { ethers } from "ethers";
import Cookies from "js-cookie";

export const isValidEthereumAddress = (address: string): boolean =>
  ethers.isAddress(address);

export const truncateEnd = (text: string, truncateLength: number = 5) => {
  if (text.length > 12) {
    return `${text.slice(0, text.length - truncateLength)}....`;
  }
  return text;
};

export const truncateAddress = (
  address: string,
  substringLength: number = 4
): string =>
  address.length <= 8
    ? address
    : `${address.substring(0, substringLength)}...${address.substring(
        address.length - substringLength
      )}`;

export const setGlobalTheme = (theme: ThemeMode) => {
  const root = window.document.documentElement;
  root.classList.remove(String(ThemeMode.DARK));
  root.classList.remove(String(ThemeMode.LIGHT));
  root.classList.add(theme);
  root.setAttribute("data-theme", theme);
};

export const copyToClipboard = async (copyText: string) => {
  if (typeof window !== "undefined") {
    await navigator.clipboard.writeText(copyText);
  }
};

export const removeAnsiCodes = (input: string) => {
  // eslint-disable-next-line no-control-regex
  const regex = /\x1B\[\d+(;\d+)*m/g;
  return input.replace(regex, "");
};

export const getTokenDetails = (
  tokenInfo: string,
  networkType: NetworkType
): IToken | undefined => {
  if (isValidEthereumAddress(tokenInfo)) {
    return (
      tokenMap[networkType]?.find(
        (token) => token.address.toLowerCase() === tokenInfo.toLowerCase()
      ) || tokenMap[networkType][0]
    );
  } else {
    return (
      tokenMap[networkType]?.find(
        (token) => token.symbol.toLowerCase() === tokenInfo.toLowerCase()
      ) || tokenMap[networkType][0]
    );
  }
};

export const mapTokenToId = (token: string) => {
  const tokens = new Map<string, number>();
  tokens.set("USDT", 825);
  tokens.set("WMATIC", 3890);
  tokens.set("WETH", 2396);
  tokens.set("DAI", 4943);
  tokens.set("USDC", 3408);
  tokens.set("AKT", 7431);
  tokens.set("MATIC", 3890);
  tokens.set("SOL", 5426);
  tokens.set("WAVAX", 5805);
  tokens.set("WBNB", 1839);
  tokens.set("tFIL", 2280);
  tokens.set("FIL", 2280);
  tokens.set("USDbC", 3408);
  tokens.set("WMNT", 27614);
  tokens.set("WXDAI", 9021);
  tokens.set("CST", 825); // TODO: token id is same as USDT
  return tokens.get(token);
};

export const getUSDAmount = (
  livePrice: number | null,
  amount: number
): string => {
  if (livePrice) {
    return `${(livePrice * Number(amount)).toFixed(6)}`;
  }
  return "";
};

export const titleCase = (str: string) => {
  if (!str) return "";
  return str.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
};

export const getCookie = (key: string) => {
  return Cookies.get(key);
};

export const removeCookie = (key: string) => {
  Cookies.remove(key);
};

export const getInstanceType = (servicesCount: number, gpu: boolean) => {
  if (servicesCount > 0) {
    return InstanceDeploymentType.MULTISERVICE;
  }
  return gpu
    ? InstanceDeploymentType.ACCELERATE
    : InstanceDeploymentType.COMPUTE;
};

export const checkGPUExists = (specs: any) => {
  if (!specs) {
    return false;
  }

  for (const resource of specs.Services) {
    if (resource.Resources.GPU && resource.Resources.GPU.Units > 0) {
      return true;
    }
  }

  return false;
};

export const getTierKey = (inputTiers: number[]): string | undefined => {
  for (const key in validTiers) {
    const tiers = validTiers[key];
    if (
      tiers.length === inputTiers.length &&
      tiers.every((tier, index) => tier === inputTiers[index])
    ) {
      return key;
    } else {
      return "community";
    }
  }
  return undefined;
};

export const getServiceState = (state: string) => {
  if (state === "starting") {
    return ServiceDetailsCardStatus.STARTING;
  }
  if (state === "waiting") {
    return ServiceDetailsCardStatus.QUEUED;
  }
  if (state === "running") {
    return ServiceDetailsCardStatus.RUNNING;
  }
  return ServiceDetailsCardStatus.STOPPED;
};

const hyperlinkPattern =
  "^(https?:\\/\\/)?([a-zA-Z0-9-]+)\\.?([a-zA-Z0-9-]+)\\.([a-zA-Z]{2,})(\\/\\S*)?$";
const hyperlinkRegex = new RegExp(hyperlinkPattern);

export const isHyperlink = (str: string): boolean => {
  return hyperlinkRegex.test(str);
};

export const isMobileDevice = (userAgent: string) => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
    userAgent
  );
};

export const getUniqueId = <T extends Array<{ id: number }>>(inputArray: T) => {
  if (inputArray.length === 0) return 1;
  const max = Math.max(...inputArray.map((p) => p.id));
  return max + 1;
};

export const isNumber = (str: string) => {
  return /^-?\d+(\.\d+)?$/.test(str.trim());
};

export const waitForTransaction = async (
  provider: any,
  txHash: string,
  timeout = 30000
) => {
  const startTime = Date.now();
  let receipt = null;

  while (Date.now() - startTime < timeout) {
    receipt = await provider.request({
      method: "eth_getTransactionReceipt",
      params: [txHash],
    });

    if (receipt) {
      if (receipt.status === "0x1") {
        return receipt; // Transaction was successful
      } else {
        throw new Error("Transaction failed");
      }
    }

    await new Promise((resolve) => setTimeout(resolve, 1000)); // Poll every second
  }

  throw new Error("Transaction timed out");
};

export type RequestMethods = "GET" | "PUT" | "PATCH" | "POST" | "DELETE";

export const requestPipeline = async ({
  url,
  method,
  body = undefined,
  isPublic = false,
  isFeApi = false,
}: {
  url: string;
  body?: string;
  method: RequestMethods;
  isPublic?: boolean;
  isFeApi?: boolean;
}) => {
  const headers: any = {
    "Content-Type": "application/json",
    entity: "supernoderz",
  };
  if (localStorage.getItem("jwt-token")) {
    headers.Authorization = `Bearer ${localStorage.getItem("jwt-token")}`;
  }
  const res = await fetch(
    `${isFeApi ? `/api` : SERVICE_API_URL + "/v1"}/${url}`,
    {
      method,
      headers,
      body,
    }
  );
  if (res.status === 204) {
    return;
  }
  // if (res.status === 401 && !isPublic) {
  //   localStorage.removeItem("jwt-token");
  //   window.open(`${window.location.origin}/`, "_self");
  // }
  return res.json();
};

export function compareVersions(version1: string, version2: string) {
  if (!version1 && !version2) return 0;
  if (!version1) return -1;
  if (!version2) return 1;
  const v1 = version1.startsWith("v") ? version1.slice(1) : version1;
  const v2 = version2.startsWith("v") ? version2.slice(1) : version2;

  const v1Parts = v1.split(".").map(Number);
  const v2Parts = v2.split(".").map(Number);

  const maxLength = Math.max(v1Parts.length, v2Parts.length);
  for (let i = 0; i < maxLength; i++) {
    const part1 = v1Parts[i] || 0;
    const part2 = v2Parts[i] || 0;

    if (part1 < part2) {
      return -1; // version1 is less than version2
    } else if (part1 > part2) {
      return 1; // version1 is greater than version2
    }
  }

  return 0; // Versions are equal
}

export const removeNonPrintableChars = (str: string) => {
  return str.replace(/[^\x20-\x7E]/g, "");
};

export const isValidEmail = (email: string) => {
  const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return regex.test(email);
};

export const encodeBase64 = (str: string): string => {
  return btoa(str);
};

export const decodeBase64 = (encodedStr: string): string => {
  return atob(encodedStr);
};

export const abbreviateString = (str: string): string => {
  if (!str) return "";
  if (str.length <= 8) return str;
  const firstPart = str.slice(0, 4);
  const lastPart = str.slice(-4);
  return `${firstPart}....${lastPart}`;
};

export const segregateNumberAndUnit = (
  input: string
): { value: number; unit: string } | null => {
  const match = input?.match(/^(\d+(\.\d+)?)([a-zA-Z]+)$/);

  if (match) {
    return {
      value: parseFloat(match[1]),
      unit: match[3],
    };
  }
  return null;
};

export const getRealtimeBandwidth = (bandwidth?: string) => {
  if (!bandwidth) return "";
  return bandwidth.split(";")[1]
    ? bandwidth.split(";")[1]
    : bandwidth.split(";")[0];
};

export const formatBandwidth = (bandwidth: string) => {
  const res = segregateNumberAndUnit(bandwidth);
  if (!res) return "";
  const { value, unit } = res;
  return `${value} ${titleCase(unit)}`;
};

export const roundToNearestMultiple = (
  num: number,
  multiple: number = 4
): number => {
  return Math.floor(num / multiple) * multiple;
};
